import { type SetupContext, isVNode, cloneVNode, createVNode } from 'vue'

export function getSlotVNodes(originalSlots: SetupContext['slots']) {
  const slotVnodes: VNode[] = []

  const getSlotVNodesFromElements = (els: VNode[], slotName: string) => {
    if (!Array.isArray(els)) {
      return
    }
    els.forEach((vnode) => {
      const isFragment = typeof vnode.type === 'symbol'
      if (isFragment && vnode.children) {
        getSlotVNodesFromElements(vnode.children as VNode[], slotName)
      } else if (isVNode(vnode)) {
        slotVnodes.push(vnode)
      }
    })
  }

  Object.keys(originalSlots).forEach((slotName) => {
    const slot = originalSlots[slotName]
    if (typeof slot !== 'function') {
      return
    }

    const els = slot()
    getSlotVNodesFromElements(els, slotName)
  })

  return slotVnodes
}

function deepCloneVNode(vnode: VNode): VNode {
  const cloned = cloneVNode(vnode)
  if (isArray(vnode.children)) {
    cloned.children = (vnode.children as VNode[]).map(deepCloneVNode)
  }
  return cloned
}

export const getClonedChildren = (vnode: VNode) => {
  const { children } = vnode

  if (isArray(children)) {
    return (children as VNode[]).map((vnode) => deepCloneVNode(vnode))
  }

  return children
}

export const getVNodeClone = (
  vnode: VNode,
  extraProps: Parameters<typeof createVNode>[1] = {},
) => createVNode(vnode, extraProps, getClonedChildren(vnode))
